<template>
  <div class="p-0 container-fluid">
    <div class="no-gutters row">
      <div class="col-lg-4">
        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="justify-content-center row">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a class="logo" href="/" style="font-size: 2rem; font-weight: bold">AVOAMERICA</a>
                    </div>
                    <h4 class="font-size-18 mt-4">Bienvenido !</h4>
                    <p class="text-muted">Inicie sesión para continuar.</p>
                  </div>
                  <div class="p-2 mt-5">
                    <form v-on:submit.prevent="mtd_send()" class="form form-horizontal">
                      <div class="auth-form-group-custom mb-4 form-group">
                        <i class="auti-custom-input-icon far fa-user"></i><label for="username" class="">Correo o DNI</label>
                        <div class="text-danger form-group">
                          <input
                            name="login"
                            placeholder="Ingresar correo o dni"
                            type="text"
                            class="form-control"
                            v-model="login"
                            v-validate="'required'"
                            data-vv-as="'correo o DNI'"
                          />
                          <span v-show="errors.has('login')" class="form-group__error">
                            <i class="fas fa-exclamation-circle"></i>
                            {{ errors.first("login") }}
                          </span>
                        </div>
                      </div>
                      <div class="auth-form-group-custom mb-4 form-group">
                        <i class="auti-custom-input-icon mdi mdi-lock-outline"></i><label for="userpassword" class="">Contraseña</label>
                        <div class="form-group">
                          <input
                            name="password"
                            placeholder="Ingresar contraseña"
                            type="password"
                            class="form-control"
                            v-model="password"
                            v-validate="'required'"
                            data-vv-as="'contraseña'"
                          />
                          <span v-show="errors.has('password')" class="form-group__error">
                            <i class="fas fa-exclamation-circle"></i>
                            {{ errors.first("password") }}
                          </span>
                        </div>
                      </div>
                      <div class="form-check">
                        <input id="customControlInline" type="checkbox" class="form-check-input form-check-input" /><label
                          for="customControlInline"
                          class="form-check-label"
                          >Recordar</label
                        >
                      </div>
                      <div class="mt-4 text-center">
                        <button type="submit" class="w-md waves-effect waves-light btn btn-primary">Ingresar</button>
                      </div>
                      <div class="mt-4 text-center">
                        <a class="text-muted" href="/auth-recoverpw"><i class="mdi mdi-lock mr-1"></i> ¿Olvidaste tu contraseña?</a>
                      </div>
                    </form>
                  </div>
                  <div class="mt-5 text-center">
                    <p>
                      © 2021 - Hecho con
                      <i class="mdi mdi-heart text-danger"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="authentication-bg">
          <div class="bg-overlay-login"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  /** Vuex */
  import { mapActions, mapGetters } from "vuex";
  export default {
    data() {
      return {
        login: "",
        password: "",
      };
    },
    computed: {
      ...mapGetters(["url_api"]),
    },
    mounted() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "home" });
      }
    },
    methods: {
      ...mapActions(["post", "execute_commit"]),
      mtd_send: function () {
        var _this = this;
        this.$validator.validate().then((valid) => {
          if (valid) {
            _this.mtd_login();
          }
        });
      },
      mtd_login: function () {
        this.post({
          url: this.url_api + "/auth/login",
          params: {
            login: this.login,
            password: this.password,
          },
        })
          .then((response) => {
            if (response.state) {
              this.execute_commit({
                mutation: "AUTH__USER_SAVE",
                payload: {
                  user: response.user,
                },
              });
              localStorage.setItem("token", response.access_token);
              this.$router.push({ name: "home" });
            } else {
              this.$alert("Cuenta temporalmente suspendida.", "Mensaje", "warning").then({});
            }
          })
          .catch(() => {});
      },
    },
  };
</script>
